import React, { useEffect, useState } from 'react'
import { Switch, Route, NavLink, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'

import Select from 'react-select';
import Edit from './Edit'
import EditTickets from './EditTickets'
import List from './List'
import Dashboard from './Dashboard'
import Container from './Container'
import NoAccess from './NoAccess'
import ImportTicketTypePage from './Pages/ImportTicketTypePage'
import BatchMessages from './Pages/BatchMessages'
import Messages from './Pages/Messages'
import ImportTicketsPage from './Pages/ImportTicketsPage'
import ImportDiceTicketTypePage from './Pages/ImportDiceTicketTypePage'
import Users from './Pages/Users'
import { GlobalConfig } from './Store/'
import StatsTickets from './StatsTickets'
import StatsTicketTypes from './StatsTicketTypes'
import StatsProducts from './StatsProducts'
import StatsEventZone from './StatsEventZone'
import StatsPurchases from './StatsPurchases'
import StatsZones from './StatsZones'
import User from './User'
import Purchase from './Purchase'
import Ticket from './Ticket'
import Tickets from './Pages/Tickets'
import ControlDevices from './Pages/ControlDevices'
import PhoneRecovery from './Pages/PhoneRecovery'
import DiceList from './Pages/DiceList'
import Wristband from './Pages/Wristband'
import EmailCode from './Pages/EmailCode'
import TickettypeZones from './Pages/TickettypeZones'
import ControlDeviceDetail from './Pages/ControlDeviceDetail'
import ControlDeviceDetailLog from './Pages/ControlDeviceDetailLog'
import PhoneDice from './Pages/PhoneDice'
import EditUsers from './Pages/EditUsers'
import { getAuth } from "firebase/auth";
import InvitationModal from './InvitationModal'
import EditPttUser from './EditPttUser';
import ListAppLiteral from './ListAppLiteral';
import ListGuestsEvents from './ListGuestsEvents';
import ListGuests from './ListGuests';
import EditGuest from './EditGuest';
import { AuthProvider } from '../contexts/AuthContext';
import { logout } from '../firebase/firebase-service'
import useLocalStorage from '../hooks/useLocalStorage'

const getLink = (environment) => {
  let baseURL = 'http://localhost:3000'
  if (environment === 'production') baseURL = 'https://manager.acst.app'
  if (environment === 'staging') baseURL = 'https://manager-staging.acst.app'
  return `${baseURL}${window.location.pathname}`
}

const Main = () => {
  const [appConfig, setAppConfig] = useState(false)
  const [userInfo, setUserInfo] = useState(false)
  const [userEvents, setUserEvents] = useState(false)
  const [userOrganizations, setUserOrganizations] = useState(false)
  const [updateEvents, setUpdateEvents] = useState(false)
  const [updateOrganizations, setUpdateOrganizations] = useState(false)
  const [hiddenMenu, setHiddenMenu] = useState(Cookies.get('hiddenMenu') == 1)
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showOrganizationSelector, setShowOrganizationSelector] = useState(false)
  const [selectedOrganization, setSelectedOrganization] = useState()
  const [adminOrganizations, setAdminOrganizations] = useState([])
  const [role, setRole] = useState(false)
  const [token, setToken] = useState(false)
  const [productionURL, setProductionURL] = useState(getLink('production'))
  const [stagingURL, setStagingURL] = useState(getLink('staging'))
  const [localURL, setLocalURL] = useState(getLink('local'))

  const updateConfig = async () => {
    setLoading(true)

    const bodyData = {
      filters: {
        organization: Cookies.get('organization'),
        event: Cookies.get('event'),
      },
    }

    fetch(`${process.env.REACT_APP_API_URL}config-config`, {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      body: JSON.stringify(bodyData),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        setLoading(false)
        if (response.status === 200) {
          setRole(response.body.role)
          setAdminOrganizations(response.body.adminOrganizations)
          setAppConfig(response.body.config)
          setUserInfo(response.body.user)
          setUserEvents(response.body.user.events || [])
          setUserOrganizations(response.body.user.organizations || [])
        } else {
          if (response.status === 403 || response.status === 400) {
            Cookies.remove('user')
            history.push('/login')
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const refreshToken = async (user) => {
    const refreshToken = await user.getIdToken(true)
    setToken(refreshToken)
  }

  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged(async (user) => {
      if (user) {
        refreshToken(user)
        setInterval(() => refreshToken(user), 50 * 60 * 100);
      } else {
        Cookies.remove('user')
        clearInterval(refreshToken);
      }
      setLoading(false)
    });

    return () => {
      unsubscribe()
    }
  }, [])

  const handleKeyDown = ({ code }) => {
    if (code === "Escape") setShowOrganizationSelector(false)
  }

  useEffect(() => {
    if (token) {
      updateConfig()
    }
    document.addEventListener("keydown", handleKeyDown)

    return () => document.removeEventListener("keydown", handleKeyDown)
  }, [token])

  useEffect(() => {
    if (updateEvents) {
      if (
        typeof updateEvents !== 'boolean' &&
        updateEvents === Cookies.get('event')
      ) {
        const event = { target: { value: 'all' } }
        updateCurrentEvent(event)
      }
      setUpdateEvents(false)
      updateConfig()
    }
  }, [updateEvents])

  useEffect(() => {
    if (updateOrganizations) {
      if (
        typeof updateOrganizations !== 'boolean' &&
        updateOrganizations === Cookies.get('organization')
      ) {
        const organization = { target: { value: 'all' } }
        updateCurrentOrganization(organization)
      }
      setUpdateOrganizations(false)
      updateConfig()
    }
  }, [updateOrganizations])

  const history = useHistory()

  const updateCurrentEvent = (event) => {
    if (event.target.value === 'all') {
      Cookies.remove('event')
      Cookies.remove('eventTimezone')
    } else {
      Cookies.set('event', event.target.value, { expires: 7 })
    }
    window.location.reload(false)
  }

  const updateCurrentOrganization = (event) => {
    if (event.target.value === 'all') {
      Cookies.remove('organization')
    } else {
      Cookies.set('organization', event.target.value, { expires: 7 })
    }
    window.location.reload(false)
  }

  const isMenuItemActive = (item) => {
    let active = false
    if (typeof item.items !== 'undefined') {
      item.items.forEach((subitem) => {
        let parsedPath = subitem.path
        if (parsedPath && parsedPath.includes(':event')) {
          parsedPath = parsedPath.replace(':event', eventSlug)
        }

        if (
          (parsedPath && history.location.pathname.includes(parsedPath)) ||
          (!parsedPath && history.location.pathname.includes(subitem.model))
        ) {
          active = true
        }
      })
    }

    if (!active) {
      const parts = history.location.pathname.split('/')
      return parts.includes(item.model)
    }
    return active
  }

  const Submenu = ({ item }) => (
    <>
      {isMenuItemActive(item) && (
        <ul className="flex flex-row pl-2 overflow-scroll text-sm bg-white md:pl-0 md:mx-4 md:flex-col whitespace-nowrap md:whitespace-normal">
          {item.items &&
            item.items.map((subitem, index) => {
              let menuSubitemPath = subitem.path
              if (menuSubitemPath) {
                menuSubitemPath = menuSubitemPath.replace(':event', eventSlug)
              } else {
                menuSubitemPath = `/admin/${eventSlug}/list/${subitem.model}`
              }

              return (
              <li key={subitem.path || subitem.model + index} className="px-2 py-2 md:py-1">
                <NavLink
                  className="py-2 md:py-0 md:block hover:text-primary hover:wght-semibold md:text-xs"
                  activeClassName="text-primary"
                  to={menuSubitemPath}
                >
                  {subitem.title}
                </NavLink>
              </li>
            )})}
        </ul>
      )}
    </>
  )

  const ToggleMenu = () => {
    return (
      <div onClick={() => {
        const hM = !hiddenMenu
        console.log('HM', hM)
        setHiddenMenu(hM)
        Cookies.set('hiddenMenu', hM ? 1 : 0)
      }} className="relative flex items-center justify-center w-8 h-8 rounded-full cursor-pointer hover:bg-primary text-primary hover:text-white">
        <span className={`text-xl ${hiddenMenu ? '' : 'md:mt-1'}`}>{hiddenMenu ? '≡' : '×'}</span>
      </div>
    )
  }

  const [ organizationEvents, setOrganizationEvents ] = useLocalStorage("organizationEvents")
  const [selectedMenuEvent, setSelectedMenuEvent] = useState()
  const [selectedMenuOrganization, setSelectedMenuOrganization] = useState()
  const [eventSlug, setEventSlug] = useState('all')

  useEffect(() => {
    const eventSlug = selectedMenuEvent ? selectedMenuEvent.slug : 'all'
    setEventSlug(eventSlug)
  }, [selectedMenuEvent])
  

  useEffect(() => {
    if (userOrganizations.length > 0) {
      const rawOrganizationsOptions = userOrganizations.map(e => ({ value: e._id, label: e.title }))
      if (Cookies.get('organization')) {
        const cookieOrganization = rawOrganizationsOptions.find(i => i.value === Cookies.get('organization'))
        if (cookieOrganization) {
          setSelectedMenuOrganization(cookieOrganization)
        } else {
          handleSelectOption(rawOrganizationsOptions[0], "organization")
        }
      } else {
        handleSelectOption(rawOrganizationsOptions[0], "organization")
      }
    }
  }, [userOrganizations])

  const getEventFromUrl = () => {
    const path = history.location.pathname.split('/')
    let eventSlug = path[2]
    if (eventSlug === 'guests') {
      eventSlug = path[3]
    }
    if (eventSlug !== 'all') {
      const selectedEvent = organizationEvents && organizationEvents.find(i => i.slug === eventSlug)
      return selectedEvent || false
    }
    return false
  }

  useEffect(() => {
    if (userEvents.length > 0) {
      const userEventsFilter = userEvents.filter(e => e.organization === Cookies.get('organization'))
      const rawEventsOptions = userEventsFilter.map(e => ({ value: e._id, label: e.title, timezone: e.tz, slug: e.slug }))
      setOrganizationEvents([...rawEventsOptions])
      if (getEventFromUrl()) {
        setSelectedMenuEvent(getEventFromUrl())
      }
    }
  }, [userEvents, selectedMenuOrganization])

  const handleSelectOption = (selected, type) => {
    if (!selected) {
      Cookies.remove(type)
      setSelectedMenuEvent()
    } else {
      if (type === "event") {
        setSelectedMenuEvent(selected)
        if (selected.timezone) {
          Cookies.set('eventTimezone', selected.timezone, { expires: 7 })
        }
      } else if (type === "organization") {
        setSelectedMenuOrganization(selected)
        setSelectedMenuEvent()
        Cookies.remove('event')
        Cookies.remove('eventTimezone')
      }

      Cookies.set(type, selected.value, { expires: 7 })
    }
    
    const parts = history.location.pathname.split('/')
    const newParts = parts.map((i, index) => {
      if (index === 2) {
        if (!selected) return 'all'
        return selected.slug
      }

      // If we are on edit page and change event selected, return no list page.
      if (index === 3 && i === 'edit') {
        // Change to list page
        return 'list'
      }
      if (index > 4 && i.length && parts[3] === 'list') {
        // Remove parts not from list page 
        return ''
      }

      return i
    })
    const newLocation = newParts.join('/')
    history.push(newLocation)
    window.location.reload(false)
  }

  // if url change, check if cookies event is the same as selectedMenuEvent
  useEffect(() => {
    const selectedEvent = getEventFromUrl()
    if (selectedMenuEvent && selectedEvent && selectedEvent._id !== selectedMenuEvent._id) {
      setSelectedMenuEvent(selectedEvent)
    }
    setProductionURL(getLink('production'))
    setStagingURL(getLink('staging'))
    setLocalURL(getLink('local'))
  }, [history.location.pathname])

  const handleLogout = () => {
    logout()
    history.replace('/login')
  }

  const Menu = () => (
    <>
      <div className="md:overflow-scroll md:flex-1 whitespace-nowrap md:whitespace-normal">
        <h1 className="items-center justify-between hidden px-4 pb-2 md:flex wght-bold">
          <NavLink to="/">{appConfig && appConfig.app.title}{process.env.REACT_APP_ENVIRONMENT?.toLowerCase() !== 'production' ? ` - ${process.env.REACT_APP_ENVIRONMENT?.slice(0, 3).toUpperCase()}` : ''}</NavLink>
        </h1>
        <div className="flex flex-row items-center pl-4 mb-4 text-xs md:items-start md:flex-col md:px-0">
          <div className="absolute top-0 left-0 mt-2 ml-1 md:mr-2 md:mt-1 md:left-auto md:right-0"><ToggleMenu /></div>
          {selectedMenuOrganization && (
            <button onClick={() => setShowOrganizationSelector(true)} className="flex flex-col pr-4 cursor-pointer hover:text-primary md:px-4 hover:wght-semibold ">
              <p className=''>{selectedMenuOrganization.label}</p>
              <p className='text-xxs'>Change organization</p>
            </button>
          )}
        </div>

        <div className="flex flex-row items-center pl-4 mb-4 text-xs md:items-start md:flex-col md:px-0">
          <div className="absolute top-0 left-0 mt-2 ml-1 md:mr-2 md:mt-1 md:left-auto md:right-0"><ToggleMenu /></div>
          <p className="hidden pr-4 cursor-default md:block md:px-4">Selected event:</p>
          <p className="pr-4 cursor-default md:hidden md:px-4">Event:</p>
          <div className='w-full'>
            <Select
              value={selectedMenuEvent || false}
              onChange={(selected) => handleSelectOption(selected, "event")}
              options={organizationEvents}
              className="z-40 text-xs"
              classNamePrefix="z-40 "
              isClearable={true}
              placeholder="All"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: '#FFC4AD',
                  primary50: '#ffb293',
                  primary: '#0300c9',
                },
                input: (styles) => ({ ...styles, borderColor: 'red' }),
              })}
              styles={{
                control: (styles) => ({
                  ...styles,
                  borderColor: "#eee",
                  minHeight: '20px',
                  width: '100%',
                  borderRightWidth: 0,
                  borderLeftWidth: 0,
                  cursor: 'pointer',
                }),
                valueContainer: (styles) => ({ ...styles, paddingTop: 0, paddingBottom: 0 }),
                input: (styles) => ({ ...styles, marginTop: 2, marginBottom: 1 }),
                dropdownIndicator: (styles) => ({ ...styles, padding: 2 }),
                menuList: (styles) => ({ ...styles, paddingTop: 0, paddingBottom: 0 }),
                option: (styles) => ({ ...styles, paddingTop: 4, paddingBottom: 4, cursor: 'pointer' }),
              }}
            />
          </div>
        </div>
        {appConfig && (
          <ul className="flex md:block">
            {appConfig.menu.map((item) => {
              let menuItemPath = item.path
              if (menuItemPath) {
                menuItemPath = menuItemPath.replace(':event', eventSlug)
              } else {
                let modelPath = item.model
                if (item.model !== 'Event' && item.items.length) {
                  const isModelInSubMenu = item.items.find(i => i.model === item.model)
                  if (!isModelInSubMenu) {
                    modelPath = item.items[0].model
                  }
                }
                menuItemPath = `/admin/${eventSlug}/list/${modelPath}`
              }

              return (
              <li
                className="mr-4 md:border-b md:border-grayLight md:mr-0"
                key={item.model}
              >
                <NavLink
                  className={`border-solid hover:text-primary border-primary md:block md:px-4 md:py-2 hover:wght-semibold`}
                  isActive={() => isMenuItemActive(item)}
                  activeClassName="text-primaryDark wght-semibold md:border-r-4 md:bg-primaryLight"
                  to={menuItemPath}
                >
                  {item.title}
                </NavLink>
                <div className="hidden md:block">
                  <Submenu item={item} />
                </div>
              </li>
            )})}
          </ul>
        )}
      </div>
      <div className="hidden text-xs select-none md:px-4 md:block">
        <p className='mt-2'>
          👋 Hi <span className="capitalize">{userInfo.name}</span>.{' '}
          <span
            onClick={handleLogout}
            className="block cursor-pointer wght-bold hover:wght-semibold"
          >
            Log out
          </span>
        </p>
        { userInfo.developer ? <div className="mt-2">
          <a className='text-xs cursor-pointer hover:wght-semibold hover:text-primary' href={localURL} target="_blank">LOCAL</a>
          &nbsp;|&nbsp;
          <a className='text-xs cursor-pointer hover:wght-semibold hover:text-primary' href={stagingURL} target="_blank">STG</a>
          &nbsp;|&nbsp;
          <a className='text-xs cursor-pointer hover:wght-semibold hover:text-primary' href={productionURL} target="_blank">PROD</a>
        </div> : <></>}
      </div>
    </>
  )


  const handleSelectOrganization = (organization) => {
    Cookies.set('organization', organization._id, { expires: 7 })
    Cookies.remove('userManagerPreferences')
    Cookies.remove('event')
    Cookies.remove('eventTimezone')
    history.push(`/admin`)
    window.location.reload(false)
  }

  const handleEditOrganization = (id) => {
    Cookies.set('organization', id, { expires: 7 })
    setShowOrganizationSelector(false)
    history.push(`/admin/all/edit/Organization/${id}`)
    window.location.reload(false)
  }

  const handleNewOrganization = () => {
    setShowOrganizationSelector(false)
    history.push(`/admin/all/edit/Organization/`)
  }

  const [showLoader, setShowLoader] = useState(true)

  useEffect(() => {
    if (userOrganizations && userOrganizations.length > 0) {
      setShowLoader(false)
    } else if (userOrganizations && userOrganizations.length === 0) {
      setShowLoader(false)

      if (window.location.pathname !== '/admin/all/edit/Organization/') {
        setShowOrganizationSelector(true)
      }
    }
  }, [userOrganizations]) 

  const handleShowModal = () => setShowModal(state => !state)
  const handleInviteOrganizationId = id => {
    setSelectedOrganization(id)
    setShowModal(true)
  }

  return (
    <div className="flex flex-col w-full min-h-screen">
      <div className="w-full md:flex">
        {!hiddenMenu && <nav className="z-40 px-4 pt-2 bg-white md:px-0 md:h-screen md:fixed md:w-2/12 md:py-8 md:border-r border-grayLight md:flex md:flex-col">
          <Menu />
        </nav>}
        {!hiddenMenu && <div className="hidden md:block md:w-2/12"></div>}
        {!hiddenMenu &&
          <div className="mb-4 md:hidden">
            {appConfig &&
              appConfig.menu.map((item) => (
                <Submenu key={item.model} item={item} />
              ))}
          </div>
        }
        <div className={`md:py-8 md:mx-auto px-4 ${hiddenMenu ? ' mt-12 md:mt-0 md:w-full' : 'md:w-10/12'}`}>
          {hiddenMenu &&
            <div className="absolute top-0 left-0 pt-1 pl-2"><ToggleMenu /></div>
          }
          {(!loading && !userOrganizations.length && !userEvents.length && history.location.pathname !== '/admin/all/edit/Organization/') &&
            <div className="block p-2 mb-4 rounded bg-orange">
              <p>
                No assigned organizations. Do you want to create a
                <span
                  onClick={() => history.replace('/admin/all/edit/Organization/')}
                  className="cursor-pointer wght-bold hover:wght-semibold"
                >
                  {" "} new organization
                </span>
                ?
              </p>
            </div>
          }

          {!loading && userOrganizations.length > 0 && !userEvents.length && history.location.pathname !== '/admin/all/edit/Event/' &&
            <div className="block p-2 mb-4 rounded bg-orange">
              <p>
                No assigned events. Do you want to create a
                <span
                  onClick={() => history.replace('/admin/all/edit/Event/')}
                  className="cursor-pointer wght-bold hover:wght-semibold"
                >
                  {" "} new event
                </span>
                ?
              </p>
            </div>
          }

          <AuthProvider>
            <GlobalConfig config={appConfig}>
              <Container>
                <Switch>
                  {userEvents.length > 0 &&
                    <Route path="/admin" exact>
                      <Dashboard />
                    </Route>
                  }
                  <Route path="/admin/:event/list/Dice">
                    <DiceList />
                  </Route>
                  <Route path="/admin/:event/import-ticket-type">
                    <ImportTicketTypePage />
                  </Route>
                  <Route path="/admin/:event/import-tickets">
                    <ImportTicketsPage />
                  </Route>
                  <Route path="/admin/:event/import-dice-ticket-type">
                    <ImportDiceTicketTypePage />
                  </Route>
                  <Route path="/admin/:event/list/BatchMessages/:page?">
                    <BatchMessages />
                  </Route>
                  <Route path="/admin/:event/list/Messages">
                    <Messages />
                  </Route>
                  <Route path="/admin/:event/list/Ticket/:page?/:filters?">
                    <Tickets organizationEvents={organizationEvents} languages={appConfig.languages} developer={userInfo?.developer} />
                  </Route>
                  <Route path="/admin/:event/edit/PttUser/:id?">
                    <EditPttUser events={organizationEvents} organization={selectedMenuOrganization} />
                  </Route>
                  <Route path="/admin/no-access">
                    <NoAccess />
                  </Route>
                  <Route path="/admin/guests/:GuestsEvent/:GuestsCategory?/:GuestsEntity?/:GuestsQuota?/edit/:name/:id?">
                    <EditGuest
                      selectedMenuEvent={selectedMenuEvent}
                      setSelectedMenuEvent={setSelectedMenuEvent}
                      menuEventsOptions={organizationEvents}
                    />
                  </Route>
                  <Route path="/admin/guests/:GuestsEvent/:GuestsCategory?/:GuestsEntity?/:GuestsQuota?/:GuestsInvitation?">
                    <ListGuests
                      selectedMenuEvent={selectedMenuEvent}
                      setSelectedMenuEvent={setSelectedMenuEvent}
                      menuEventsOptions={organizationEvents}
                    />
                  </Route>
                  <Route path="/admin/guests">
                    <ListGuestsEvents
                      modelName="GuestsEvents"
                      menuEventsOptions={organizationEvents}
                    />
                  </Route>
                  <Route path="/admin/:event/list/AppLiteral/:page?">
                    <ListAppLiteral
                      modelName="AppLiteral"
                      setUpdateEvents={setUpdateEvents}
                      setUpdateOrganizations={setUpdateOrganizations}
                      selectedMenuEvent={selectedMenuEvent}
                      selectedMenuOrganization={selectedMenuOrganization}
                    />
                  </Route>
                  <Route path="/admin/:event/edit/Ticket/:id?">
                    <EditTickets
                      setUpdateEvents={setUpdateEvents}
                      setUpdateOrganizations={setUpdateOrganizations}
                    />
                  </Route>
                  <Route path="/admin/:event/edit/:name/:id?">
                    <Edit
                      setUpdateEvents={setUpdateEvents}
                      setUpdateOrganizations={setUpdateOrganizations}
                    />
                  </Route>
                  <Route path="/admin/:event/stats/users/:page?">
                    <Users />
                  </Route>
                  <Route path="/admin/:event/stats/tickets">
                    <StatsTickets />
                  </Route>
                  <Route path="/admin/:event/stats/tickettypes">
                    <StatsTicketTypes organizationTitle={selectedMenuOrganization?.label} />
                  </Route>
                  <Route path="/admin/:event/stats/products">
                    <StatsProducts organizationTitle={selectedMenuOrganization?.label} />
                  </Route>
                  <Route path="/admin/:event/stats/purchases">
                    <StatsPurchases menuEventsOptions={organizationEvents} />
                  </Route>
                  <Route path="/admin/:event/stats/zones/:id?">
                    <StatsZones />
                  </Route>
                  <Route path="/admin/:event/stats/:name/:id/:type?/:page?">
                    <StatsEventZone />
                  </Route>
                  <Route path="/admin/user/:userId/:profileId/:ticketId">
                    <Ticket />
                  </Route>
                  <Route path="/admin/user/:userId/:profileId">
                    <User />
                  </Route>
                  <Route path="/admin/:event/purchase/:purchaseId">
                    <Purchase />
                  </Route>
                  <Route path="/admin/:event/controldevices/:deviceId/logs">
                    <ControlDeviceDetailLog />
                  </Route>
                  <Route path="/admin/:event/controldevices/:deviceId">
                    <ControlDeviceDetail />
                  </Route>
                  <Route path="/admin/:event/list/controldevices">
                    <ControlDevices menuEventsOptions={organizationEvents} />
                  </Route>
                  <Route path="/admin/:event/phonerecovery">
                    <PhoneRecovery />
                  </Route>
                  <Route path="/admin/:event/wristband">
                    <Wristband />
                  </Route>
                  <Route path="/admin/:event/tickettype-zones">
                    <TickettypeZones />
                  </Route>
                  <Route path="/admin/:event/phone-dice">
                    <PhoneDice />
                  </Route>
                  <Route path="/admin/:event/users">
                    <EditUsers />
                  </Route>
                  <Route path="/admin/:event/user-email-code">
                    <EmailCode />
                  </Route>
                  <Route path="/admin/:event/list/:name/:page?">
                    <List
                      setUpdateEvents={setUpdateEvents}
                      setUpdateOrganizations={setUpdateOrganizations}
                      selectedMenuEvent={selectedMenuEvent}
                      selectedMenuOrganization={selectedMenuOrganization}
                    />
                  </Route>
                </Switch>
              </Container>
            </GlobalConfig>
          </AuthProvider>

          <div className="p-4 mt-4 text-xs md:hidden">
            <p>
              👋 Hi <span className="capitalize">{userInfo.name}</span>. Want to{' '}
              <span
                onClick={handleLogout}
                className="cursor-pointer wght-bold hover:wght-semibold"
              >
                log out
              </span>
              ?
            </p>
          </div>
        </div>
      </div>

      {showOrganizationSelector && (
        <div className='fixed z-40 flex items-center justify-center w-full h-full bg-white'>
          <div className='w-full m-2 md:w-1/2'>
            {
              userOrganizations.length > 0 ? (
                <>
                  <div
                    className="absolute top-0 right-0 flex items-center justify-center w-8 h-8 mt-4 mr-4 rounded-full cursor-pointer hover:bg-primary text-primary hover:text-white"
                    onClick={() => setShowOrganizationSelector(false)}
                  >
                    <span className='text-xl md:mt-1'>×</span>
                  </div>
                  <h1 className='text-xl'>Select an organization</h1>
                  <ul>
                    {userOrganizations && userOrganizations.map((organization) => (
                      <li key={organization._id}>
                        <div className={`w-full p-2 my-2 flex items-center justify-between border rounded border-primary hover:bg-primary hover:text-white cursor-pointer`}>
                          <button onClick={() => handleSelectOrganization(organization)} className={`w-full flex flex-col items-start justify-start`}>
                            <p className=' wght-bold'>{organization.title}</p>
                            {organization?.role && <p className='text-xs text-gray wght-semibold'>{organization.role.toUpperCase()}</p>}
                          </button>
                          {adminOrganizations && adminOrganizations.includes(organization._id) &&
                            <>
                              <button
                                className="flex items-center h-full px-2 py-1 mr-2 text-xs text-white border border-white rounded cursor-pointer bg-primary hover:wght-semibold"
                                onClick={() => handleEditOrganization(organization._id)}
                              >
                                <span className=''>Edit</span>
                              </button>
                              <span onClick={() => handleInviteOrganizationId(organization._id)} className="h-full px-2 py-1 mr-2 text-xs border border-white rounded cursor-pointer text-primaryLighter bg-success hover:wght-semibold">Share</span>
                            </>
                          }
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <h1 className='text-xl'>Welcome to AccessTicket</h1>
              )
            }
            <button onClick={handleNewOrganization} className="hover:text-primary">Create a new organization</button>
          </div>
        </div>
      )}

      {showModal && token &&
        <div className='z-50'>
          <InvitationModal token={token} handleShowModal={handleShowModal} modelName={'Organization'} selectedOrganization={selectedOrganization} role={role}/>
        </div>
      }

      {showLoader && (
        <div className='absolute z-50 flex items-center justify-center w-full h-full bg-white'>
          <div className='w-full m-2 text-center md:w-1/2'>
            Loading...
          </div>
        </div>
      )}
    </div>
  )
}
export default Main
